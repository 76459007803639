<template>
  <base-section id="menu" space="0">
    <v-container id="review-container">
      <h3>¿Cómo calificarías tu experiencia en nuestro local?</h3>
      <article id="faces">
        <div class="emoji-excellent" @click="setSatisfactionLevel(5)">
          <div class="face">
            <div class="eye-left"></div>
            <div class="eye-right"></div>
            <div class="mouth-excellent"></div>
            <div
              v-if="getSatisfactionLevel == 5"
              class="selected-excellent"
            ></div>
          </div>
        </div>
        <div class="emoji-very-good" @click="setSatisfactionLevel(4)">
          <div class="face">
            <div class="eye-left"></div>
            <div class="eye-right"></div>
            <div class="mouth-very-good"></div>
            <div
              v-if="getSatisfactionLevel == 4"
              class="selected-very-good"
            ></div>
          </div>
        </div>
        <div class="emoji-good" @click="setSatisfactionLevel(3)">
          <div class="face">
            <div class="eye-left"></div>
            <div class="eye-right"></div>
            <div class="mouth-good"></div>
            <div v-if="getSatisfactionLevel == 3" class="selected-good"></div>
          </div>
        </div>
        <div class="emoji-bad" @click="setSatisfactionLevel(2)">
          <div class="face">
            <div class="eye-left"></div>
            <div class="eye-right"></div>
            <div class="mouth-bad"></div>
            <div v-if="getSatisfactionLevel == 2" class="selected-bad"></div>
          </div>
        </div>
        <div class="emoji-very-bad" @click="setSatisfactionLevel(1)">
          <div class="face">
            <div class="eye-left"></div>
            <div class="eye-right"></div>
            <div class="mouth-very-bad"></div>
            <div
              v-if="getSatisfactionLevel == 1"
              class="selected-very-bad"
            ></div>
          </div>
        </div>
      </article>
      <article id="inputs">
        <label>Tu nombre (opcional)</label>
        <v-text-field
          class="pb-4"
          v-model="review.name"
          :loading="loading"
          dense
          required
          flat
          hide-details
          label="Nombre"
          rounded
          filled
        />
        <label>Teléfono (opcional)</label>
        <v-text-field
          class="pb-4"
          v-model="review.phone"
          :loading="loading"
          dense
          required
          flat
          hide-details
          label="Teléfono"
          rounded
          filled
        />
        <label>Correo Electrónico</label>
        <v-text-field
          :class="{ 'pb-4': true, 'error-input': emailMissing }"
          v-model="review.email"
          :loading="loading"
          dense
          required
          flat
          hide-details
          label="Ingresa tu email"
          rounded
          filled
        />
        <div v-if="emailMissing == true" class="error-input-text">
          Ingresa una dirección de correo electrónico válida.
        </div>
        <label>Dejanos aquí tu reseña sobre tu experiencia</label>
        <v-textarea
          :class="{ 'pb-4': true, 'error-input': reviewMissing }"
          v-model="review.comments"
          :loading="loading"
          dense
          required
          flat
          hide-details
          rounded
          filled
        />
        <div v-if="reviewMissing == true" class="error-input-text">
          La reseña no puede estar vacía.
        </div>
        <v-btn
          @click="validateReview"
          :loading="loading"
          color="info"
          class="px-5"
          block
          depressed
          x-large
          rounded
        >
          ENVIAR RESEÑA
        </v-btn>
      </article>
    </v-container>
  </base-section>
</template>

<script>
import { sendReview } from "@/plugins/axios";
export default {
  name: "ReviewSection",
  data: () => {
    return {
      loading: false,
      validForm: true,
      emptyRule: [(v) => !!v || "Necesario"],
      review: {
        name: null,
        phone: null,
        email: null,
        comments: null,
        satisfactionLevel: 5,
      },
      emailMissing: false,
      reviewMissing: false,
    };
  },
  methods: {
    cleanInputs() {
      this.review = {
        name: null,
        phone: null,
        email: null,
        comments: null,
        satisfactionLevel: 5,
      };
    },
    setSatisfactionLevel(level) {
      this.review.satisfactionLevel = level;
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validateReview() {
      if (
        this.getEmail != "" &&
        this.getEmail != null &&
        this.validateEmail(this.getEmail) != null &&
        this.getReview != "" &&
        this.getReview != null
      ) {
        this.sendReview();
      } else {
        if (
          this.getEmail == "" ||
          this.getEmail == null ||
          this.validateEmail(this.getEmail) == null
        ) {
          this.emailMissing = true;
        } else {
          this.emailMissing = false;
        }
        if (this.getReview == "" || this.getReview == null) {
          this.reviewMissing = true;
        } else {
          this.reviewMissing = false;
        }
      }
    },
    async sendReview() {
      const payload = {
        shopCode: this.$route.query.commerceId,
        review: this.review,
      };
      this.loading = true;
      try {
        const result = await sendReview(payload);
        let success = true;
        if (result.data.responseCode != 0) {
          success = false;
        } else {
          this.cleanInputs();
        }

        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          msg: result.data.responseMessage,
          success: success,
        });

        //Redirecciono al thankyou
        if (success) {
          this.$router.push({
            name: "ThankYouReview",
          });
        }
      } catch (ex) {
        this.$store.commit("snackbar/OPEN_SNACKBAR", {
          msg: "Tuvimos un problema al enviar la reseña a nuestros servidores. Reintentá más tarde por favor",
          success: false,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    getSatisfactionLevel() {
      return this.review.satisfactionLevel;
    },
    getEmail() {
      return this.review.email;
    },
    getReview() {
      return this.review.comments;
    },
  },
};
</script>
<style scoped>
#review-container {
  text-align: center;
}

#faces {
  display: flex;
  justify-content: space-between;
}

.error-input {
  border: 2px solid red;
  margin-bottom: 5px !important;
  padding: 0px !important;
}

.error-input-text {
  text-align: left;
  margin-left: 5px !important;
  font-size: 0.75em;
  margin-bottom: 12px;
  color: red;
}

.selected-excellent {
  position: absolute;
  cursor: pointer;
  width: 60px;
  top: 67px;
  height: 13px;
  background: #3cff00;
  border-radius: 5% 5%;
}

.selected-very-good {
  position: absolute;
  cursor: pointer;
  width: 60px;
  top: 67px;
  height: 13px;
  background: #ade921;
  border-radius: 5% 5%;
}

.selected-good {
  position: absolute;
  cursor: pointer;
  width: 60px;
  top: 67px;
  height: 13px;
  background: #e9d521;
  border-radius: 5% 5%;
}

.selected-bad {
  position: absolute;
  cursor: pointer;
  width: 60px;
  top: 67px;
  height: 13px;
  background: #e9aa21;
  border-radius: 5% 5%;
}

.selected-very-bad {
  position: absolute;
  cursor: pointer;
  width: 60px;
  top: 67px;
  height: 13px;
  background: #ff3c00;
  border-radius: 5% 5%;
}

.emoji-excellent {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: #3cff00;
  border-radius: 50%;
  margin: 20px 0px;
}

.emoji-very-good {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: #ade921;
  border-radius: 50%;
  margin: 20px 0px;
}

.emoji-good {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: #e9d521;
  border-radius: 50%;
  margin: 20px 0px;
}

.emoji-bad {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: #e9aa21;
  border-radius: 50%;
  margin: 20px 0px;
}

.emoji-very-bad {
  position: relative;
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: #ff3c00;
  border-radius: 50%;
  margin: 20px 0px;
}

.face {
  width: inherit;
  height: inherit;
}

.eye-left {
  position: absolute;
  width: 7px;
  height: 7px;
  background: black;
  left: 37px;
  top: 17px;
  border-radius: 50%;
}

.eye-right {
  position: absolute;
  width: 7px;
  height: 7px;
  background: black;
  right: 37px;
  top: 17px;
  border-radius: 50%;
}

.mouth-excellent {
  position: absolute;
  width: 50px;
  height: 74px;
  border: 4px solid transparent;
  top: -25px;
  left: 4px;
  box-sizing: border-box;
  border-radius: 50%;
  border-bottom-color: black;
}

.mouth-very-good {
  position: absolute;
  width: 32px;
  height: 17px;
  border: 4px solid transparent;
  top: 24px;
  left: 14px;
  box-sizing: border-box;
  border-radius: 50%;
  border-bottom-color: black;
}

.mouth-good {
  position: absolute;
  width: 28px;
  height: 14px;
  border: 4px solid transparent;
  top: 35px;
  left: 16px;
  box-sizing: border-box;
  border-radius: 0px;
  border-top-color: black;
}

.mouth-bad {
  position: absolute;
  width: 34px;
  height: 14px;
  border: 4px solid transparent;
  top: 35px;
  left: 13px;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: black;
}

.mouth-very-bad {
  position: absolute;
  width: 50px;
  height: 74px;
  border: 4px solid transparent;
  top: 35px;
  left: 5px;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: black;
}

/* Aca son los del thank you */
.weborder-message-icon {
  display: flex;
  justify-content: center;
}

.weborder-message-title {
  color: #00ad06;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: center;
}

.weborder-message-text {
  width: 250px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;
  font-size: 20px;
}
</style>
